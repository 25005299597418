import 'core-js/stable'
// import './config/public-path'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueProgressBar from 'vue-progressbar'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'vue-material-design-icons/styles.css'

import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import config from './config'
import liff from '@line/liff'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueMaterial)

Vue.prototype.$log = console.log.bind(console)

// console.log('liffid = ' + config.liffid )
const isInClient = liff.isInClient()
// console.log("isInClient", isInClient)

Vue.use(VueProgressBar,{
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '15px'
})

Vue.use(Vuetify)
const opts = {}
export default new Vuetify(opts)

// start ทดสอบแบบ localhost
liff.init({
  liffId: config.liffid
}).then(()=>{
  console.log("liff init success !");

  if (isInClient) {
    console.log("is in Line in-app browser");
// end ทดสอบแบบ localhost

      initApp();

// start ทดสอบแบบ localhost
  } else {
    console.log("is not in Line in-app browser ==>" + liff.isLoggedIn);
    if (liff.isLoggedIn()) {
      console.log("initapp")
      initApp();
    } else {
      console.log("liff login")
      liff.login();
    }
  }
}).catch(err => {
  // Error happens during initialization
  console.log(err.code, err.message);
});
// end ทดสอบแบบ localhost

function initApp() {
  new Vue({
    el: '#app',
    vuetify: new Vuetify(),
    router,
    store,
    icons,
    template: '<App/>',
    components: {
      App
    }
  })
}

