import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  showNav: true,
  popupregis:false,
  popupregis_buff:false,

  profile: {},
  // profile: {
  //   // userId: "Ue33b3518f2da9103548b5d847249ad1c",  // Ue78adad2da133c321eb3953d65822b27 U7758d1e4d89cdb3bfc6190e6dd02a84a
  //   // userId: "U7758d1e4d89cdb3bfc6190e6dd02a84a",
  //   userId: "Ue33b3518f2da9103548b5d847249ad1c",
  //   displayName: "My name is Meow",
  //   pictureUrl: "https://img.poki.com/cdn-cgi/image/quality=78,width=600,height=600,fit=cover,g=0.5x0.5,f=auto/b5bd34054bc849159d949d50021d8926.png",
  // },
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  mutations
})