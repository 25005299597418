var env = process.env.NODE_ENV || 'development'

var config = {
  development: {
      liffid: '1654958907-BYOz4ALe', // origin
      server: 'http://localhost:8000',

      // liffid: '1657029901-81M21kOZ', // test 
      // server: 'https://line.teedev.online/api',
  },
  production: {
      liffid: 'VUE_APP_LIFFID',
      server: '/api'
  },
}

module.exports = config[env]